<template>
  <b-card v-if="hasSurveysToDisplay || error">
    <div class="text-center my-5" v-if="loading">
      <div class="spinner-border text-primary"></div>
    </div>
    <b-alert
      variant="info"
      size="sm"
      show
      class="mt-3 d-flex align-items-center"
      v-if="!loading && surveys && surveys.length === 0 && !error"
    >
      <i class="bi bi-info-circle-fill fs-3 text-info me-3"></i>
      <span>{{ $t('results.surveys.emptyResult') }}</span>
    </b-alert>
    <b-button
      :hidden="true"
      variant="primary"
      class="m-0"
      @click="onClickRetry"
      v-if="!loading && surveys && surveys.length === 0"
    >
      {{ $t('surveys.retry') }}
    </b-button>
    <b-alert
      variant="danger"
      size="sm"
      show
      class="my-3 d-flex align-items-center"
      v-if="!loading && error"
    >
      <i class="bi bi-exclamation-octagon-fill fs-3 text-danger me-3"></i>
      <span>{{ $t('error.couldNotLoadSurveys') }}</span>
    </b-alert>
    <div class="mb-0" v-if="!loading && donePublishedSurveys.length">
      <h3>{{ $t('results.surveys.published.title') }}</h3>
      <ResultsSurveysList :surveys="donePublishedSurveys" />
      <div class="ml-4 mt-3">
        <b-button
          class="btn-sm btn-round"
          variant="secondary"
          @click="navigateToResults"
        >
          {{ $t('dashboard.button.goToAllResults') }}
        </b-button>
      </div>
    </div>
    <div
      class="mb-0"
      v-if="
        !loading && completedSurveys.length && donePublishedSurveys.length === 0
      "
    >
      <h3>{{ $t('results.surveys.completed.latest') }}</h3>
      <ResultsSurveysList :surveys="[latestCompletedSurvey]" />
      <div class="ml-4 mt-3" v-if="completedSurveys.length > 1">
        <b-button
          class="btn-sm btn-round"
          variant="secondary"
          @click="navigateToResults"
        >
          {{ $t('dashboard.button.goToAllResults') }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'ResultsSurveys',
  computed: {
    surveys() {
      return this.$store.getters.getResultsSurveys
    },
    donePublishedSurveys() {
      return this.surveys.filter(
        (s) =>
          s.details.status === 'published' &&
          (!s.numberOfCategoriesCompleted ||
            s.numberOfCategoriesCompleted < s.numberOfCategories)
      )
    },
    completedSurveys() {
      return this.surveys.filter((s) => s.details.status === 'completed')
    },
    latestCompletedSurvey() {
      if (this.completedSurveys.length) {
        return this.completedSurveys[0]
      } else {
        return null
      }
    },
    hasSurveysToDisplay() {
      return this.donePublishedSurveys.length || this.completedSurveys.length
    },
    loading() {
      return this.$store.getters.getResultsLoading
    },
    error() {
      return this.$store.getters.getResultsError
    },
    organizationName() {
      return this.$store.getters.userInfo.organizationName
    }
  },
  methods: {
    onClickRetry() {
      this.loadResultsSurveys()
    },
    loadResultsSurveys() {
      this.$store.dispatch('loadResultsSurveys', {
        organizationName: this.organizationName
      })
    },
    navigateToResults() {
      window.location = `/results/#/results`
    }
  },
  mounted() {
    this.loadResultsSurveys()
  },
  components: {
    ResultsSurveysList: () => import('@/components/ResultsSurveysList')
  }
}
</script>

<style lang="scss" scoped>
.main-panel > .content {
  margin-top: 0;
}

.remaining {
  color: #888;
}

.survey-item {
  // .name {
  //   font-size: 16px;
  // }

  .name,
  .complete {
    width: 100%;
    height: 24px;

    .progress {
      width: 100%;
    }
  }
}
</style>
